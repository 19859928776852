'use client';

import type { TermsDocument } from '../types/terms-document';
import { Configuration } from '../configuration';

export class AxeptioService {
  constructor(
    webhooks: { onSdkReady: () => void },
    private readonly configuration: Configuration,
  ) {
    if (!configuration.isEnabled || typeof window === 'undefined') return;

    (window as any).axeptioSettings = {
      ...(window as any).axeptioSettings,
      clientId: configuration.clientId,
      userCookiesDomain: this.configuration.userCookiesDomain,
    };

    (window as any)._axcb = ((window as any)._axcb ?? []).concat((axeptio: any): void => {
      axeptio.on('ready', () => {
        const sheet = new CSSStyleSheet();
        sheet.replaceSync(`
          div[color="#ed3491"]::before { background: #030d20cc !important; }
          div[class*="Step__LogoContainer"] { display: none !important; }
          section[data-position="prev"] { display: none !important; }
          section[data-position="next"] { display: none !important; }
          section[data-position="current"]>div { background: transparent !important; }
          div[class*="ContractWidgetV2__SearchDiv"] { height: 0 !important; }
          button[class*="ContractWidgetV2__BackButton"] { display: none !important; }
          div[class*="Widget__WidgetTitle"] { margin: 0 !important; }
          div[class*="Widget__WidgetTitle"] p { margin: 0 !important; }
          div[class*="Widget__WidgetTitle"]>div { width: 85% !important; min-height: 4.4rem !important; display: flex !important; flex-direction: column !important; justify-content: center !important; }
          div[class*="GenericWidgetManager__GenericWidgetContainerStyle"] * { transform: unset !important; }
          div[class*="GenericWidgetManager__GenericWidgetContainerStyle"]>div { display: flex !important; flex-direction: column !important; left: 0 !important; top: 0 !important; justify-content: center !important; align-items: center !important; height: 100% !important; }
          div[class*="GenericWidgetManager__GenericWidgetContainerStyle"] section { position: relative !important; left: 0 !important; top 0 !important; }
          div[class*="axeptio_widget_wrapper"] { border-radius: 8px 8px 0px 0px !important; }
        `);
        window.document.getElementsByClassName('needsclick')[0].shadowRoot?.adoptedStyleSheets.push(sheet);
        return webhooks.onSdkReady();
      });
    });
  }

  private _getContractNameFromDocument(document: TermsDocument): string {
    const contractsNames =
      (window as any)?.axeptioSDK?.config.contractsV2.map(({ name }: { name: string }) => name) ?? [];
    const { type, locale, campus } = document;

    if (contractsNames.includes(`${type}-${campus}-${locale}`)) return `${type}-${campus}-${locale}`;

    if (contractsNames.includes(`${type}-${campus}`)) return `${type}-${campus}`;

    if (contractsNames.includes(`${type}-${locale}`)) return `${type}-${locale}`;

    if (contractsNames.includes(type)) return type;

    throw new Error('Contract not found');
  }

  public openDocumentForReading(document: TermsDocument): void {
    if (!this.configuration.isEnabled || typeof window === 'undefined') return;

    const name = this._getContractNameFromDocument(document);

    (window as any).axeptioSDK?.openContract(name, { readOnly: true });
  }

  public openDocumentsForConsent(documents: TermsDocument[], signerId: string): void {
    if (!this.configuration.isEnabled || typeof window === 'undefined') return;

    const contracts = documents.map((document) => ({
      name: this._getContractNameFromDocument(document),
      readOnly: false,
      consentFor: signerId,
    }));
    (window as any).axeptioSDK?.checkContracts(contracts);
  }

  get isReady(): boolean {
    if (!this.configuration.isEnabled || typeof window === 'undefined') return false;
    return (window as any).axeptioSDK?.isReady || false;
  }
}
